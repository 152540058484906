import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import AppStoreLink from './AppStoreLink';
import './MainNavigation.scss';

const MainNavigation = ({ selected, banner }) => {
  const [collapsedState, setCollapsed] = useState(true);

  const handleMenuClick = () => {
    setCollapsed(!collapsedState);
  };

  const getItemClassName = (item) => (item === selected
    ? 'navigation-item-selected'
    : 'navigation-item-not-selected');

  const classNames = cx(
    !collapsedState && 'navigation-expanded',
  );

  return (
    <div className="navigation">
      <div className="navigation-top-row">
        <div className="navigation-logo">
          <img
            src="../images/icon.png"
            alt="Icon with a white S in front of green leafs"
          />
          <Link to="/">Sugarless</Link>
        </div>
        <button
          className="navigation-hamburger-icon"
          type="button"
          onClick={handleMenuClick}
        >
          ☰

        </button>
        <ul className={classNames}>
          <li className={getItemClassName('home')}>
            <Link to="/">Home</Link>
          </li>
          <li className={getItemClassName('faq')}>
            <Link to="/faq">FAQ</Link>
          </li>
          <li className={getItemClassName('quiz')}>
            <Link to="/quiz">Quiz</Link>
          </li>
          <li className="navigation-item-not-selected">
            <a href="https://drive.google.com/file/d/1EO-biowIOpFNMemqs8ERdvIQ_0SWthtM/" target="_blank" rel="noreferrer">Press Kit</a>
          </li>
        </ul>
        <AppStoreLink />
      </div>
      {banner}
    </div>
  );
};

MainNavigation.propTypes = {
  selected: PropTypes.string,
  banner: PropTypes.instanceOf(React.ReactNode),
};

MainNavigation.defaultProps = {
  selected: '',
  banner: null,
};

export default MainNavigation;
