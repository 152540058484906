import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import MainNavigation from './MainNavigation';
import favicon from '../images/favicon.ico';
import './Layout.scss';

const Layout = ({
  selectedMenuItem, head, banner, children, className,
}) => (
  <>
    <Helmet
      title="Sugarless — Get Free From Sugar"
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          href: favicon,
        },
      ]}
    >
      {head}
    </Helmet>
    <MainNavigation selected={selectedMenuItem} banner={banner} />
    <main className={cx('container', 'layout', banner && 'layout-offset-for-banner', className)}>
      {children}
      <div className="row layout-footer text-center">
        <p>
          <a href="https://discord.gg/wY2heJtbyh">
            <img
              src="../images/discord-logo.svg"
              alt="Discord logo"
              className="layout-footer-icon"
            />
            Discord
          </a>
          {' '}
          |
          {' '}
          <a href="https://reddit.com/r/sugarless/">
            <img
              src="../images/reddit-logo.svg"
              alt="Reddit logo"
              className="layout-footer-icon"
            />
            /r/sugarless
          </a>
        </p>
        <p>
          <Link to="/privacy">App Privacy Policy</Link>
          {' '}
          |
          {' '}
          <Link to="/privacy-web">Web Privacy Policy</Link>
          {' '}
          |
          {' '}
          <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank" rel="noreferrer">Terms of Use</a>
        </p>
        <p>
          Drop us a line at
          {' '}
          <a href="mailto:hello@sugarlessapp.com">hello@sugarlessapp.com</a>
        </p>
        <p>Slava Ukraini!</p>
      </div>
    </main>
  </>
);

Layout.propTypes = {
  selectedMenuItem: PropTypes.string,
  head: PropTypes.instanceOf(React.Element),
  banner: PropTypes.instanceOf(React.ReactNode),
  className: PropTypes.string,
  children: PropTypes.instanceOf(React.ReactNode),
};

Layout.defaultProps = {
  selectedMenuItem: '',
  head: null,
  banner: null,
  className: '',
  children: null,
};

export default Layout;
