import React from 'react';
import { Link } from 'gatsby';

import './NavigationBanner.scss';

const NavigationBanner = () => (
  <div className="navigation-banner">
    <p>
      Wondering if you could be addicted to sugar?
      {' '}
      <Link to="/quiz">Take&nbsp;our&nbsp;short&nbsp;quiz!</Link>
    </p>
  </div>
);

export default NavigationBanner;
