import * as React from 'react';

import AppStoreLink from '../components/AppStoreLink';
import Layout from '../components/Layout';
import NavigationBanner from '../components/NavigationBanner';
import './home.scss';

const HomePage = () => (
  <Layout
    selectedMenuItem="home"
    banner={<NavigationBanner />}
    className="home"
  >
    <div className="row content-section">
      <div className="col-sm-8">
        <h1>Sugarless</h1>
        <h2>Free yourself from sugar.</h2>
        <p>
          With Sugarless, you grow a beautiful garden by becoming sugar-free. Make use of
          psychotherapy principles and let us guide you on a step-by-step Journey to
          liberate yourself from sugar addiction.
        </p>
        <p>
          Research shows that sugar can make you sick, wreck your smile and make you feel
          miserable too. Sugarless helps you start a healthier life today.
        </p>
        <div className="col-auto"><AppStoreLink className="mt-4" /></div>
      </div>
      <div className="col-sm-4">
        <img
          className="img-fluid home-hero-image"
          src="../images/iphone-home.png"
          alt="An iPhone with an app showing statistics regarding habit tracking and a lush grove of trees"
        />
      </div>
    </div>

    <div className="row content-section">
      <div className="col-sm-8">
        <h2>Choose your goal</h2>
        <p>
          Pick from different strategies to stick to, from just eliminating
          added sugar to staying full keto.
        </p>
      </div>
      <div className="col-sm-4">
        <img
          className="img-fluid home-screenshot"
          src="../images/screenshots/difficulty.png"
          alt="Screenshot of an app showing encouragement based on progress"
        />
      </div>
    </div>

    <div className="row content-section content-section-reverse">
      <div className="col-sm-4">
        <img
          className="img-fluid home-screenshot"
          src="../images/screenshots/garden.png"
          alt="Screenshot of an app showing encouragement based on progress"
        />
      </div>
      <div className="col-sm-8">
        <h2>Grow a blooming garden</h2>
        <p>
          Grow a garden that flourishes when you meet your goals and withers when you
          don&apos;t.
        </p>
      </div>
    </div>

    <div className="row content-section">
      <div className="col-sm-8">
        <h2>Learn and get motivation</h2>
        <p>
          Get daily tips, motivational messages, and research facts to help you stay
          sugar-free.
        </p>
      </div>
      <div className="col-sm-4">
        <img
          className="img-fluid home-screenshot"
          src="../images/screenshots/encouragement.png"
          alt="Screenshot of an app showing encouragement based on progress"
        />
      </div>
    </div>

    <div className="row content-section content-section-reverse">
      <div className="col-sm-4">
        <img
          className="img-fluid home-screenshot"
          src="../images/screenshots/calendar.png"
          alt="Screenshot of an app showing encouragement based on progress"
        />
      </div>
      <div className="col-sm-8">
        <h2>Stay consistent</h2>
        <p>
          Be reminded daily to log your progress. See your streak and percentage of successful
          days.
        </p>
      </div>
    </div>

    <div className="row content-section">
      <div className="col-sm-8">
        <h2>Complete the journey</h2>
        <p>
          Our premium Journey makes your sugar-free lifestyle even easier and longer-lasting.
          Based on  cognitive-behavioral therapy principles, it will gradually guide you
          through 18 steps, exploring your motivations, triggers, cravings, and reactions.
        </p>
      </div>
      <div className="col-sm-4">
        <img
          className="img-fluid home-screenshot"
          src="../images/screenshots/journey.png"
          alt="Screenshot of an app showing encouragement based on progress"
        />
      </div>
    </div>

    <div className="row justify-content-center mt-5">
      <AppStoreLink />
    </div>

    <div className="row content-section">
      <h2>About Us</h2>
      <p>
        Hi! We are Viktor and Michal. We used to devour cakes and chocolate bars like a swarm of
        locusts, and unfortunately, we still sometimes do. Being a software engineer and a
        psychologist, we decided to create an app to help people just like us.
      </p>
      <p>
        But we didn&apos;t want it to be just another habit tracker. That&apos;s why we built
        Sugarless to leverage psychological knowledge on habit change and therapeutic approaches
        to addiction. We believe that whether you use the free version or start the premium
        Journey, we can help you to deal with this unhealthy habit.
      </p>
    </div>

    <div className="row content-section">
      <h2>Let&apos;s stay in touch</h2>
      <p>
        Join the Sugarless community and receive advice, information and updates that help
        you win over sugar.
      </p>
      <a href="mailto:hello@sugarlessapp.com">hello@sugarlessapp.com</a>
    </div>
  </Layout>
);

export default HomePage;
