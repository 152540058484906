import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { APP_STORE_LINK } from '../consts';
import './AppStoreLink.scss';

const AppStoreLink = ({ className }) => (
  <div className={cx('link-appstore', className)}>
    <img
      src="../images/apple-logo.svg"
      alt="Apple logo"
      className="link-appstore-icon"
    />
    <a href={APP_STORE_LINK} className="stretched-link inverted">
      <span>Download on the</span>
      <br />
      <span className="link-appstore-large">App Store</span>
    </a>
  </div>
);

AppStoreLink.propTypes = {
  className: PropTypes.string,
};

AppStoreLink.defaultProps = {
  className: '',
};

export default AppStoreLink;
